<template>
  <div class="">
    <ModalVue
      size="xl"
      ref="createFlowTitle"
      :id="`segments${isEditMode}`"
      :title="`Create Flow`">
      <div class="px-4">
       
        <FlowsByGoal :store="store"></FlowsByGoal>
       
       
   
      </div>
    </ModalVue>
  </div>
</template>
<script>
import FlowsByGoal from './FlowsByGoal.vue';
import { mapActions } from 'vuex';
// import Button from "../../Layout/Button.vue";
import ModalVue from "../../Layout/Modal.vue";
export default {
  props: {
    title: {
      type: String,
      default: "Flow",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isLoadingSubmitBtn: {
      type: Boolean,
      default: false,
    },
    isLoadingFirstTime:{
 type: Boolean,
      default: false,
    },
   
  },
  data() {
    return {
      segments: [],
      selectedQuestion: [],
      SelectedQuiz: [],
      isLoading:false,
      flow: {
        flow_id:null
      },
    };
  },

  components: {
    ModalVue,
    // Button,
    FlowsByGoal
  },
  computed:{

  },
  methods: {
    ...mapActions(['fetchData']),
    async store(flow_id) {
        this.isLoading=true
        const data=await this.fetchData({
         axiosMethod:'post',
        resource: 'flow',
        method: 'store',
        params:{flow_id:flow_id}

      })
      console.log(data)
      if(data)
      {
        this.closeModal();
       
       this.$router.push(`/manage/emails/flows/${data.uuid}`)
       
      }
      else{

        this.$toasted.show('Error while creating flow', {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      }
        this.isLoading=false
      
    },
   
  
    openModal() {
     
        this.$refs.createFlowTitle.open();
   
    },
    closeModal() {
     
      this.$refs.createFlowTitle.close();
    },

 
  },

  mounted() {
    
  },
};
</script>
<style scoped>
.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3 ease all;
  background-color: transparent;
}
.question-control {
  border: 1px solid rgba(255, 162, 1, 1);
}
.quizell-form-control:focus-visible {
  border: 1px solid #d2d8e0 !important;
}
label {
  color: rgba(115, 115, 141, 1);
  font-size: 12px;
}
.condition {
  display: inline-flex;
  padding: 16px 14px;
  align-items: center;
  gap: 4px;
  color: #0d7de4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
</style>
